import React, { FC, Fragment, useState } from 'react';
import {
  Box,
  FormHelperText,
  Grid,
  Typography,
  useTheme,
} from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { PageTextContentType } from '../../../hooks/useContentMgmt';
import DatePicker from '../../date-picker';
import Dropdown from '../../dropdown';
import { subText1, boldText } from './quoteCss';
import { FormState } from '../../../hooks/useFormState.types';
import { JobQuoteModal } from '../confirm-cost/QuoteForm.rules';
import { formatDate } from '../../../util/format';
import {
  convertTimeToAPStyle,
  getTimeRangeString,
} from '../../../util/date-utils';
import TextLink from '../../text-link';
import { GetJobOutputEvType } from '../../../hooks/pge-plus/useInstallerJobDetailsQuery';
import {
  ApplicationEvSectionType,
  Maybe,
  SectionEvInstall,
} from '../../../__generated__/pgeplus-types';
import { titleCase } from '../../../util/string-utils';

interface Props {
  title?: string;
  parentPage?: string;
  content: PageTextContentType;
  jobQuoteForm?: FormState<JobQuoteModal>;
  jobData?: GetJobOutputEvType;
  editDateAndTime?: boolean;
  installationDateTime?:
    | Maybe<{
        start: string;
        end: string;
      }>
    | undefined;
}

const InstallerDateAndTime: FC<Props> = ({
  title,
  parentPage,
  content,
  jobQuoteForm,
  jobData,
  editDateAndTime,
  installationDateTime,
}: Props) => {
  const theme = useTheme();
  const customerRequest = {
    fontSize: '20px !important',
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px !important',
    },
  };
  const today = formatDate(new Date(), 'MM/dd/yyyy');
  const [editDateAndTimeFlag, setEditDateAndTimeFlag] = useState<
    boolean | undefined
  >(editDateAndTime);

  const evInstall = jobData?.application?.details?.find(
    sec => sec.sectionType === ApplicationEvSectionType.EvInstall,
  ) as SectionEvInstall;

  return (
    <Fragment>
      <Grid container item xs={12} css={{ rowGap: 30 }}>
        <Grid item xs={12}>
          <Typography
            css={{
              fontSize: '20px',
              color: '#3D5265',
              marginTop: theme.spacing(4),
              marginBottom: theme.spacing(2.5),
            }}
            variant="h4"
          >
            {title}
          </Typography>
          {(parentPage === 'complete-quote' || parentPage === 'view-quote') && (
            <Fragment>
              <Typography
                css={{
                  fontSize: '20px !important',
                  [theme.breakpoints.down('sm')]: {
                    fontSize: '16px !important',
                  },
                  marginBottom: theme.spacing(1),
                }}
              >
                {content.get('CUSTOMER_REQUEST')}
              </Typography>
              <Box css={{ marginBottom: '12px' }}>
                <Typography css={{ ...customerRequest }}>
                  {evInstall?.data?.schedulePreference?.dayOfWeek
                    ?.map(arr => titleCase(arr))
                    .join(' , ')}
                </Typography>
                <Typography css={{ ...customerRequest }}>
                  {evInstall?.data?.schedulePreference?.timeFrame
                    ?.map(arr => titleCase(arr))
                    .join(' , ')}
                </Typography>
              </Box>
            </Fragment>
          )}
          {parentPage !== 'complete-quote' && (
            <>
              {parentPage === 'view-quote' && (
                <Grid item>
                  <Typography
                    css={{
                      fontSize: '20px !important',
                      [theme.breakpoints.down('sm')]: {
                        fontSize: '16px !important',
                      },
                      marginTop: 20,
                      marginBottom: theme.spacing(1),
                    }}
                  >
                    {content.get('REUSABLE_INSTALLATION_DATE_AND_TIME')}
                  </Typography>
                </Grid>
              )}
              <Grid item container justify="space-between">
                <Grid item>
                  <Typography
                    variant="body1"
                    css={{ ...subText1, ...boldText }}
                  >
                    {installationDateTime &&
                      getTimeRangeString(installationDateTime)?.date}
                  </Typography>
                  <Typography
                    variant="body1"
                    css={{ ...subText1, ...boldText }}
                  >
                    {installationDateTime &&
                      convertTimeToAPStyle(
                        getTimeRangeString(installationDateTime)?.timeRange!,
                      )}
                  </Typography>
                </Grid>
                {parentPage === 'confirm-quote' && (
                  <Grid
                    item
                    css={{
                      alignSelf: 'end',
                    }}
                  >
                    <TextLink
                      plain
                      style={{
                        fontWeight: 600,
                      }}
                      onClick={e => {
                        e.preventDefault();
                        setEditDateAndTimeFlag(!editDateAndTimeFlag);
                      }}
                    >
                      Edit
                      <span
                        css={{
                          verticalAlign: 'middle',
                        }}
                      >
                        {editDateAndTimeFlag ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </span>
                    </TextLink>
                  </Grid>
                )}
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default InstallerDateAndTime;
